<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title>
          <v-icon>mdi-hair-dryer-outline</v-icon>
          Dados do profissional.
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              type="category"
              category-show-all
              :categories="categories"
              category-text="name"
              :events="events"
              :event-color="getEventColor"
              @click:event="showEvent"
              @change="fetchEvents"
            >
              <template v-slot:day-body="{ date, week }">
                <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY }"
                ></div>
              </template>
            </v-calendar>

            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
<script>
// import {mapGetters} from "vuex";
// import store from "@/store";
import { mask } from "vue-the-mask";

import {
  BMS_GET
  // BM_DAYS_OFF_GET,
  // BM_GET,
  // BM_EDIT,
  // BM_CREATE,
  // BM_SEARCH,
} from "@/store/actions/bm.type";
import store from "@/store";
import { BM_RESET_STATE } from "@/store/mutations/bm.type";
import { mapGetters } from "vuex";
import { SCHEDULES_GET } from "@/store/actions/schedule.type";
// import {BM_RESET_STATE} from "@/store/mutations.type";

export default {
  name: "bm",
  directives: { mask },
  props: {
    // id: {
    //   default: 0,
    //   type: Number,
    //   required: true
    // }
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(BM_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(BM_RESET_STATE),
      store.dispatch(BMS_GET),
      store.dispatch(SCHEDULES_GET)
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    //Lista de Usuários
    pagination: {
      sortBy: "name"
    },
    search: "",
    selected: [],
    formIsValid: false,

    value: "",
    ready: false,
    focus: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1"
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party"
    ],
    categories: [
      "John Smith",
      "Cabelelila Leila",
      "Tori Walker",
      "Maria Santos",
      "Vanessa A. Silva",
      "Grabriela B. Dos Santos"
    ]
  }),
  methods: {
    showEvent({ nativeEvent, event }) {
      console.log(nativeEvent, event);
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    buscar() {
      // store.dispatch(USER_SEARCH);
      // console.log("buscar");
    },
    //Fim Methods Lista Usuarios

    //ag
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    //
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    // fetchEvents({ start, end }) {
    //   let bms = [];
    //   for (let b = 0; b < this.bms.length; b++) {
    //     bms.push(this.bms[b]);
    //   }

    //   this.categories = bms;

    //   const events = [];

    //   const min = new Date(`${start.date}T00:00:00`);
    //   const max = new Date(`${end.date}T23:59:59`);
    //   const days = (max.getTime() - min.getTime()) / 86400000;
    //   const eventCount = this.rnd(days, days + 20);

    //   for (let i = 0; i < this.schedules.length; i++) {
    //     // const allDay = this.rnd(0, 3) === 0
    //     // const firstTimestamp = this.rnd(min.getTime(), max.getTime())
    //     // const first = new Date(firstTimestamp - (firstTimestamp % 900000))
    //     // const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
    //     // const second = new Date(first.getTime() + secondTimestamp)
    //     console.log(this.schedules[i].serviceName);
    //     events.push({
    //       name: this.schedules[i].serviceName,
    //       start: this.schedules[i].startTime,
    //       end: this.schedules[i].endTime,
    //       color: "deep-purple",
    //       timed: false,
    //       category: this.schedules[i].bmName,
    //       details: this.schedules[i]
    //     });
    //   }

    //   this.events = events;
    // },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  computed: {
    // ...mapState({
    //   courses: state => state.
    // })

    ...mapGetters(["bms", "checkUsers", "schedules"]),
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    }
  },
  watch: {}
};
</script>
